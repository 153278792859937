.AboutMePage {
  background-color: #fce1e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.left-col {
  text-align: left;
}

.left-col-heading {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 55.6px;
  margin-top: 5%;
}

.right-col-image {
  margin-top: 20%;
}

.left-col-text {
  color: #444444;
  font-family: "GlacialIndifferenceRegular";
  font-size: 18px;
}

@media (max-width: 1024px) {
  .right-col-image {
    width: 350px;
    height: 300px;
    margin-bottom: 20%;
  }
}
