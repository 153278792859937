.NamePage-header {
  font-size: 92px;
  color: black;
  font-family: "GlacialIndifferenceBold";
  letter-spacing: 10px;
}

.NamePage-subheader {
  font-size: 55px;
  color: #444444;
  font-family: "GlacialIndifferenceRegular";
  letter-spacing: 5px;
}

.NamePage-subheader:hover {
  font-size: 75px;
}

.NamePage {
  background-color: #fce1e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
