.ProjectsPage {
  background-color: #fce1e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.DataScience-header,
.SoftwareEng-header {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 40.6px;
  margin-top: 5%;
  text-align: left;
  margin-bottom: 2%;
}

.col {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.swe-one,
.swe-two,
.swe-three,
.swe-four,
.ds-one,
.ds-two {
  margin-right: 7.5%;
  margin-left: 7.5%;
}

.swe-heading,
.ds-heading {
  color: #444444;
  font-family: "GlacialIndifferenceRegular";
}

@media (max-width: 1024px) {
  .swe-one,
  .swe-two,
  .swe-three,
  .swe-four,
  .ds-one,
  .ds-two {
    margin: 7.5%;
  }

  .DataScience-header,
  .SoftwareEng-header {
    margin-bottom: 5%;
  }
}
